
import React from "react"
import SEO from "../components/seo"

import "../scss/terms-and-conditions.scss"

const TermsPage = () => (
  <div>
    <SEO title="Terms & Conditions" keywords={[`youpal`, `youpal group`, `Terms & Conditions`]} />
    <div className="bgImageDiv"></div>
      <section className="container termsPage">
        <div className="col">
          <div className="header"><h2>Terms and Conditions</h2></div>
          <div className="mainBody col">
            <h4>Introduction</h4>
            <p>Youpal AB as an owner of “youpalgroup.com” is providing you with this policy to clearly state how and why we use your personal data. It will also give you the options that are at your disposal in controlling the personal data shared with us. 
</p>
            <p className="text-center"><strong>
            PLEASE READ THIS TERMS OF USE CAREFULLY AND CONTACT US WITH ANY QUESTIONS OR CONCERNS ABOUT OUR PRACTICES  
            </strong></p>
            <p>By accessing this Website, you accept these terms and conditions in full. Your use of this Website constitutes your agreement to all these rules. Do not continue to use YoupalGroup’s website if you do not accept all of the terms and conditions stated on this page. 
</p>
            <h4>Definition</h4>
            <p>
            “User” and“You” - the person accessing this Website and accepting YoupalGroup’s terms and conditions. Any and all visitors to our Website shall be deemed as “users”.
<br />“Company”, “YoupalGroup”, “We”, “Our” and “Us” - Youpal AB, company registered and acting under the law of Sweden, registry code 559140-3471, with registered address Alströmergatan 36, 11247 Stockholm, Sweden..
<br />“Website” - set of various web pages operated and provided by the Company.

            </p>
            <h4>Description of Website</h4>
            <p>
            The purpose of this Website is to get the User acquainted with the information about services that YoupalGroup provides. For avoidance of any doubt, services will be provided under separate agreement on services provision between the User and the Company that stipulates terms and conditions of cooperation.
            <br />The User understands, acknowledges and agrees that the Website offered shall be provided “AS IS” and the Company shall not assume any responsibility or obligation for the timeliness, missed delivery, deletion and/or any failure to store user content, communication, personalization settings, etc.
            <br />Furthermore, the User acknowledges and agrees that the Website provided and made available is the sole property of the Company. At its discretion, the Company may offer additional services or update, modify or revise any current content and services, and these Terms shall apply to any and all additional services, all updated, modified or revised unless otherwise stipulated. The Company does hereby reserve the right to cancel and cease offering any of the aforementioned services. 

            </p>
            <h4>Intellectual Property</h4>
            <p>
            All right, title, and interest in and to YoupalGroup, including our website, our existing or future applications, our APIs, databases, and the content our employees or partners submit or provide through our Services are and will remain the exclusive property of YoupalGroup. The User is granted a limited license for viewing the material contained on this Website. The User may not use the site in any manner which could damage, disable, overburden or impair the Website or interfere with any other User’s use and enjoyment of the Website. The User may not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided through the Website.  
            </p>
            <h4>Service Rules and Conduct</h4>
            <p>This Website is provided for your own personal, non-commercial use and will make no other use of the Website without the express written permission of YoupalGroup. You shall not use the Website for any purpose that is prohibited by these Terms, including but not limited to: 
</p>
            <p>
            • Republish material from our website;

<br /><br />• Sell, rent or sub-license material from our website;

<br /><br />• Reproduce, duplicate or copy material or make available to the public in other way all content or any part of the content provided in the Website or from the emails and other messages from
<br />• YoupalGroup, if its is not expressly permit; 

<br /><br />• Redistribute content from YoupalGroup Website (unless content is specifically made for redistribution);

<br /><br />• Circulate document or other work product generated by YoupalGroup for marketing purposes;
<br />Access, tamper with, or use non-public areas of the platform (including content storage), YoupalGroup computer systems, or the technical delivery systems of YoupalGroup service providers;

<br /><br />• Disable, interfere with, or try to circumvent any of the features of the platforms related to security or probe, scan, or test the vulnerability of any of our systems;

<br /><br />• Copy, modify, create a derivative work of, reverse engineer, reverse assemble, or otherwise attempt to discover any source code of or content on YoupalGroup platform or Services;

<br /><br />• Access or search or attempt to access or search our platform by any means (automated or otherwise) other than through our currently available search functionalities that are provided via our website, mobile apps, or API (and only pursuant to those API terms and conditions). You may not scrape, spider, use a robot, or use other automated means of any kind to access the Website;

<br /><br />• In any way use the Website to send altered, deceptive, or false source-identifying information (such as sending email communications falsely appearing as YoupalGroup); 
or interfere with, or disrupt, (or attempt to do so), the access of any user, host, or network, including, without limitation, sending a virus, overloading, flooding, spamming, or mail-bombing the platforms or services, or in any other manner interfering with or creating an undue burden on the Website; or

<br /><br />• Gain or attempt to gain unauthorized access to any accounts, computer systems, or networks connected to any connections server through hacking, password mining, or any other means.

<br /><br />• If a User will find the information on the Website to violate the copyright of any third party, the User must immediately inform YoupalGroup. The information will then immediately be deleted upon request from the User, if YoupalGroup finds it to violate the copyright of a third party. 



            </p>
            <h4>Privacy Policy</h4>
            <p>
            Your personal data and all other information you provide to us we deal in accordance with our Privacy Policy (link). 
<br />YoupalGroup guarantees your privacy and that all information held by us is not forwarded to a third party without your consent, unless otherwise provided by law or other legal decisions.

            </p>
            <h4>Limitation of Liability</h4>
            <p>
            The information and software provided in the Website may include inaccuracies or typographical errors and changes are therefore periodically added by YoupalGroup or its suppliers to the information herein.
            <br />YoupalGroup make every effort to ensure that its computer infrastructure is error- and virus-free but does not warrant that any material available for downloading from the service will be free from infection, viruses and/ or other code that has contaminating or destructive properties. The User is responsible for implementing sufficient procedures and virus checks (including anti-virus and other security checks) to satisfy its particular requirements for the accuracy of data input and output. 
            <br />To the maximum extent, permitted by applicable law, in no event, shall YoupalGroup and/or its suppliers be liable for any direct, indirect, punitive, incidental, special or consequential damages, or any damages whatsoever arising out of or in any way connected to the use or performance of the Website, including but not limited to:

            </p>
            <p>
            loss of revenue;
<br />•	loss of business;
<br />•	loss of opportunity;
<br />•	loss of anticipated savings;
<br />•	loss of goodwill;
<br />•	loss of reputation;
<br />•	loss or damage to or corruption of data;
<br />•	loss of use of money or otherwise,
            </p>
            <p>
            We shall have no responsibility or liability for any content appearing on your Web site. You agree to indemnify and defend us against all claims arising out of or based upon your Website. No link(s) may appear on any page on your Web site or within any context containing content or materials that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.
            <br />Our website may contain links to third party’s websites or services but we shall not be liable for any usage in connection with the content and links supplied by third parties. You may use these websites and services but you agree that we have no responsibility for any results of this using. Users must understand that it is their own risk and liability. Third parties may use their own terms and conditions Users need to accept for using the services. Any advice, offers, opinions, statements or any other content expressed or made available by third parties, including information providers or Users are those of the authors or distributors and not of us. We are not responsible or reliable for any content supplied by third parties on such service. 
 
            <br /><br />YoupalGroup’s liability shall be limited in accordance with the below terms and conditions. In specific circumstances and in respect of certain matters, or parts thereof, YoupalGroup may apply additional limitations. If YoupalGroup has informed the client about such additional limitations before the initiation of a matter, or a part of a matter for which that limitation applies, that limitation shall apply in addition to the below limitations.

            </p>
            <h4>How to Contact us </h4>
            <p>The best way to get in touch with us is to contact us at oleh.ch@youpal.se. We’d love to hear your questions, concerns and feedback about our Services. 
</p>
          </div>
        </div>
      </section>
  </div> 
)

export default TermsPage

